/********************************************************************************
*                                                                               *
* This document and any information contained herein is the property of         *
* Eddyfi NDT Inc. and is protected under copyright law. It must be considered   *
* proprietary and confidential and must not be disclosed, used, or reproduced,  *
* in whole or in part, without the written authorization of Eddyfi NDT Inc.     *
*                                                                               *
* Le présent document et l’information qu’il contient sont la propriété         *
* exclusive d’Eddyfi NDT Inc. et sont protégés par la loi sur le droit          *
* d’auteur. Ce document est confidentiel et ne peut être divulgué, utilisé ou   *
* reproduit, en tout ou en partie, sans l'autorisation écrite d’Eddyfi NDT Inc. *
*                                                                               *
********************************************************************************/

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';

import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

registerPlugin(FilePondPluginFileValidateSize);

export function Activate() {
  const history = useHistory();
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [fileUri, setfileUri] = useState(true);

  const handleClose = () => {
    setShow(false);
    history.push('/');

  }
  const handleShow = () => setShow(true);

  return (
    <div>
      <h1>
        <Trans>activateLicense.title</Trans>
      </h1>
      <p>Here you can activate your software license for your offline computer or instrument. Please follow the steps below:</p>
      <h2>Step 1</h2>
      <hr/>
      <p>To save the offline activation request file, go back to the offline computer or instrument which you will be working on and launch the software. The Licensing Module window will automatically appear. To proceed, click on Manage, followed by Offline, and then Request Activation. Save the offline activation request file onto a USB flash drive.</p>
      <h2>Step 2</h2>
      <hr/>
      <p>
        Insert the USB flash drive into an available USB port on this device and select the activation request file (<strong>ActivationRequest.eddylic</strong>) below, then click on the &nbsp;
        <button className="filepond--file-action-button" type="button" data-align="right" style={{
          backgroundColor: 'black',
          cursor: 'default'
        }}>
          <svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 10.414v3.585a1 1 0 0 1-2 0v-3.585l-1.293 1.293a1 1 0 0 1-1.414-1.415l3-3a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1-1.414 1.415L14 10.414zM9 18a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2H9z" fill="currentColor" fill-rule="evenodd"></path>
          </svg>
        </button>&nbsp;button.
      </p>
      <FilePond
        instantUpload={false}
        allowMultiple={false}
        labelIdle={t('activateLicense.fileIdle')}
        labelFileWaitingForSize={t('activateLicense.fileWaitingForSize')}
        labelFileSizeNotAvailable={t('activateLicense.fileSizeNotAvailable')}
        labelFileLoading={t('activateLicense.fileLoading')}
        labelFileLoadError={t('activateLicense.fileLoadError')}
        labelButtonProcessItem={t('activateLicense.buttonProcessItem')}
        labelFileProcessing={t('activateLicense.fileProcessing')}
        labelFileProcessingError={(response) => { return t(response.body) }}
        labelFileProcessingComplete={t('activateLicense.fileProcessingComplete')}
        labelFileProcessingAborted={t('activateLicense.fileProcessingAborted')}
        labelFileRemoveError={t('activateLicense.fileRemoveError')}
        labelTapToCancel={t('activateLicense.tapToCancel')}
        labelTapToRetry={t('activateLicense.tapToRetry')}
        labelButtonRemoveItem={t('activateLicense.buttonRemoveItem')}
        labelButtonAbortItemLoad={t('activateLicense.buttonAbortItemLoad')}
        labelButtonRetryItemLoad={t('activateLicense.buttonRetryItemLoad')}
        labelButtonAbortItemProcessing={t('activateLicense.buttonAbortItemProcessing')}
        labelButtonUndoItemProcessing={t('activateLicense.buttonUndoItemProcessing')}
        labelButtonRetryItemProcessing={t('activateLicense.buttonRetryItemProcessing')}
        labelMaxFileSizeExceeded={t('activateLicense.maxFileSizeExceeded')}
        labelMaxFileSize={t('activateLicense.maxFileSize')}
        server={{
          process: {
            url: '/activation',
            method: 'POST',
            onload: (response) => {
              setfileUri(JSON.parse(response).key);

              handleShow();

              return response.key;
            },
            onerror: (response) => {
              return response;
            }
          },
          post: null,
          revert: null,
          restore: null,
          load: null,
          fetch: null
        }}
        name="file"
        allowFileSizeValidation={true}
        minFileSize="100B"
        maxFileSize="1MB"/>
      <hr/>
      <h2>Step 3</h2>
      <hr/>
      <h5>Magnifi:</h5>
      <ul>
        <li>Plug the USB flash drive to the laptop where you want to activate Magnifi.</li>
        <li>Open Magnifi and complete the offline activation.</li>
      </ul>
      <h5>Capture:</h5>
      <ul>
        <li>Download or print the instruction PDF file to continue activating your license on the disconnected instrument or computer.</li>
        <ul>
          <li>
            <a className="download-link" href="/docs/Capture_Offline licence management.pdf" download>Capture Instructions</a>
          </li>
        </ul>
      </ul>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans>activateLicense.operationCompleted</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your activation request has been received.</p>
          <p>To continue, click the Download button below and save the activation file on your USB flash drive, then follow Step 3.</p>
          <p className="text-center">
            <Button variant="primary" size="xxl" href={fileUri}>
              <Trans>activateLicense.download</Trans>
            </Button>
          </p>
        </Modal.Body>
      </Modal>
      <br/><br/>
    </div>
  );
}